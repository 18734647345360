import { createGlobalState } from "react-hooks-global-state";
import { nanoid } from "nanoid";

const initialState = {
  user: "",
  room: {
    name: nanoid(6),
    autoGenerated: true,
  },
  socket: null,
  messages: [],
};
const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
